/* Footer.css */

.footer {
  background-color: #fff;
  color: #333;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 250px;
}

.footer-logo {
  width: 200px;
  margin-bottom: 10px;
}

.footer-description {
  margin-top: 10px;
}

.footer-section h3 {
  margin-bottom: 15px;
  font-size: 1.8rem;
}

.footer-section p,
.footer-section a {
  margin-bottom: 10px;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.8;
}

.footer-social-icons {
  display: flex;
  gap: 10px;
}

.footer-social-icons a {
  color: #333;
  font-size: 24px;
  transition: color 0.3s;
}

.footer-social-icons a:hover {
  color: #28a745;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a:hover {
  color: #28a745;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 0.5px solid #ccc;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .footer-logo {
    margin-left: 20%;
  }
}