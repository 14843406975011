/* src/AboutUs.css */

.section-aboutus {
    background-color: #90bc79;
    text-align: center;
    padding: 20px 0;
    color: #fff;
    width: 100%;
    margin-top: -65px;
    height: 365px;
    background-image: url("/public/background.webp");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
}

.section-aboutus::before {
    content: "";
    position: absolute;
    top: -65px;
    left: 0;
    width: 100%;
    height: calc(100% + 65px);
    background: rgba(255, 255, 255, 0.1);
    clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.section-aboutus h1 {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: bold;
}

.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;
}

.hero-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.hero-image {
    width: 70%;
    max-width: 400px;
    margin-right: 90px;
    
}

.hero-text {
    flex: 1;
}

.hero-text h1 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.hero-text .highlight {
    color: #90bc79;
}

.hero-text p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

.contact-now {
    background-color: #90bc79;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
}

.contact-now:hover {
    background-color: #7aa862;
}

.about-container {
    padding: 20px;
    margin-top: 40px;
}

.about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

/* About heading styling */
.about-heading {
    font-size: 24px;
    /* Adjust heading font size */
    color: #333;
    /* Heading color */
    margin: 20px 10px;
    text-align: center;
}

/* About content styling */
.about-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
    /* Full width */
    max-width: 1200px;
    /* Limit the maximum width */
    padding: 0 20px;
    /* Add padding for spacing */
    box-sizing: border-box;
    /* Include padding in width calculation */
}

/* About paragraph styling */
.about-paragraph {
    font-size: 16px;
    /* Adjust paragraph font size */
    color: #666;
    /* Paragraph color */
    margin: 0 10px;
    /* Adjust spacing below paragraph */
    width: 100%;
    /* Set full width for better responsiveness */
    max-width: 650px;
    /* Limit maximum width */
    word-wrap: break-word;
    /* Allow long words to break and wrap */
    text-align: center;
    flex: 1;
    /* Allow the paragraph to take available space */
}

/* About image container styling */
.about-image {
    margin: 0 20px;
    /* Reduce margin for better spacing */
    border-radius: 4%;
    overflow: hidden;
    flex-shrink: 0;
    /* Prevent the image from shrinking */
}

/* About image styling */
.about-image img {
    width: 100%;
    /* Full width for better responsiveness */
    max-width: 350px;
    /* Limit maximum width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
}

.about-section:nth-child(even) .about-content {
    flex-direction: row-reverse;
}

.about-section:nth-child(odd) .about-content {
    flex-direction: row;
}

/* Horizontal line styling */
hr {
    margin: 40px 0;
    /* Added margin for space between sections */
    border: none;
    border-top: 2px solid #ccc;
    /* Horizontal line color */
}

/* Responsive styling */
@media (max-width: 768px) {
    .hero-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 20px;
    }

    .hero-content {
        display: block;
        align-items: center;
        width: 90%;
    }

    .hero-image {
        margin: 45px;
    }

    .hero-text {
        text-align: center;
    }

    .hero-text h1 {
        font-size: 34px;

    }
    .about-heading {
        margin: 0px 0px;
    }


    .about-content {
        display: block;
        flex-direction: column;
        /* Stack content vertically */
        align-items: center;
        /* Center align content */
        text-align: center;
        /* Center align text */
        padding: 0 20px;
        /* Add padding for spacing */
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    .about-paragraph {
        width: 100%;
        /* Full width for paragraphs on smaller screens */
        text-align: left;
        /* Left align text */
        margin: 0 0 0px 0;
        /* Adjust margin for spacing */
    }

    .about-image {
        margin: 20px 0;
        width: 100%;
        max-width: 350px;
        align-items: center;
    }

    .about-image img {
        width: 100%;
        height: auto;

    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .hero-section {
        padding: 60px 20px;
    }

    .hero-image {
        max-width: 300px;
        margin-right: 50px;
    }

    .hero-text h1 {
        font-size: 32px;
    }

    .about-heading {
        font-size: 22px;
    }

    .about-content {
        padding: 0 15px;
    }

    .about-paragraph {
        font-size: 15px;
    }

    .about-image img {
        max-width: 300px;
    }
}

/* Responsive styling for large desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
    .hero-section {
        padding: 70px 40px;
    }

    .hero-image {
        max-width: 350px;
        margin-right: 70px;
    }

    .hero-text h1 {
        font-size: 34px;
    }

    .about-heading {
        font-size: 26px;
    }

    .about-content {
        padding: 0 30px;
    }

    .about-paragraph {
        font-size: 16px;
    }

    .about-image img {
        max-width: 330px;
    }
}

/* Responsive styling for extra-large desktops (1441px and above) */
@media (min-width: 1441px) {
    .hero-section {
        padding: 90px 50px;
    }

    .hero-image {
        max-width: 400px;
        margin-right: 90px;
    }

    .hero-text h1 {
        font-size: 38px;
    }

    .about-heading {
        font-size: 28px;
    }

    .about-content {
        padding: 0 40px;
    }

    .about-paragraph {
        font-size: 18px;
    }

    .about-image img {
        max-width: 400px;
    }
}
