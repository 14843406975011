.banner {
  width: 100%;
  background-color:  #90bc79 ;
  color: white;
  padding: 10px 0;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}

.banner-text {
  display: inline-block;
  font-size: 1.5rem; /* Increase text size */
  animation: marquee 12s linear infinite;
}

.banner-text span {
  margin-right: 50px; /* Adjust the space between the texts */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
