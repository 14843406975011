/* ViewCart.css */

.view-my-cart {
    max-width: 800px;
    margin: 0 auto;
    padding: 80px;
}

.my-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}


.my-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
}

.quantity-control {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.quantity-control button {
    padding: 5px 10px;
    cursor: pointer;
    background: none;
    border: none;
    color: black;
}

.cart-but {
    display: flex;
    justify-content: flex-end;
    /* Align buttons to the right */
    margin-top: 20px;
}

.my-checkout {
    padding: 10px 20px;
    cursor: pointer;
    background-color: green;
    border: none;
    color: white;
}


.remove-my-item {
    background: none;
    color: rgb(240, 162, 162);
    cursor: pointer;
}
.remove-my-item:hover {
    color: red; /* Change text color on hover */
    background-color: transparent; /* Remove background color on hover */
}

@media only screen and (max-width: 768px) {
    .view-my-cart {
        padding: 20px;
        margin-top: 100px;
    }
  
    .my-item {
        flex-direction: column;
        align-items: center;
    }
  
    .my-item img {
        width: 120px;
        height: 120px;
        margin-right: 0;
        margin-bottom: 10px;
    }
  
    .quantity-control {
        margin-top: 5px;
    }
  
    .cart-but {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
  
    .cart-but a,
    .my-checkout {
        width: 100%; /* Full width buttons */
        margin: 5px 0;
        text-align: center;
    }
  }
  

