.section-howtouse {
  background-color: #90bc79; /* Green background color */
  text-align: center; /* Center the text horizontally */
  padding: 20px 0; /* Add some padding for spacing */
  color: #fff; /* White text color for better visibility */
  width: 100%; /* Full width */
  margin-top: -65px; /* Ensure there is space from the navbar */
  height: 365px;
  background-image: url("/public/background.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}

.section-howtouse::before {
  content: "";
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: calc(100% + 65px);
  background: rgba(255, 255, 255, 0.1);
  clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.section-howtouse h1 {
  margin: 0; /* Remove default margin */
  position: absolute; /* Absolute positioning for fine-tuning */
  top: 60%; /* Adjust this value to move the heading a little below the center */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust vertical positioning */
  font-size: 3rem;
  font-weight: bold;
}

.section-usage {
  background-color: #90bc79; /* Green background color */
  text-align: center; /* Center the text horizontally */
  padding: 20px 0; /* Add some padding for spacing */
  color: #fff; /* White text color for better visibility */
  width: 100%; /* Full width */
  margin-top: 30px; /* Ensure there is space from the navbar */
  margin-bottom: 60px; /* Ensure there is space from the navbar */
  height: 700px;
  background-image: url("/public/background.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}

.section-usage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.section-usage .content {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
}

.section-usage h1 {
  font-size: 2.5rem;
  margin-top: 160px;
  font-weight: normal;
  color: black;
}

.section-usage .highlight {
  font-weight: bold;
  color: #fff;
}

.section-usage p {
  font-size: 1rem;
  margin-bottom: 40px;
  font-weight: normal;
}

.carousel .carousel-item {
  margin-top: 30px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  height: 400px;
}

.carousel .carousel-item .icon {
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;

}

.carousel .carousel-item h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.carousel .carousel-item p {
  font-size: 1rem;
}

.carousel .control-arrow {
  position: absolute; /* Ensure the arrows are positioned absolutely within the carousel */
  top: 50%; /* Center the arrows vertically */
  transform: translateY(-50%); /* Correct vertical centering */
  z-index: 2; /* Ensure the arrows are above other elements */
  width: 40px; /* Adjust arrow width */
  height: 40px; /* Adjust arrow height */
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background for better visibility */
  display: flex; /* Center the arrow icon inside */
  justify-content: center; /* Center the arrow icon inside */
  align-items: center; /* Center the arrow icon inside */
  cursor: pointer; /* Add a pointer cursor on hover */
  color: #fff; /* White color for the arrow icon */
}

/* Specific positions for left and right arrows */
.carousel .control-prev.control-arrow {
  margin-top: 270px !important; /* Adjust this value to move the left arrow inside the carousel */
  margin-left: 10px;
}

.carousel .control-next.control-arrow {
  margin-top: 270px !important; /* Adjust this value to move the left arrow inside the carousel */
  margin-right: 10px;
}



@media (max-width: 768px) {
  .section-usage {
    padding: 10px 0;
    margin-top: 15px;
    margin-bottom: 30px;
    height: auto;
    font-size: 2rem;
  }
  
  .section-usage .content {
    width: 90%;
    padding: 10px;
  }
  
  .section-usage h1 {
    font-size: 2rem;
    margin-top: 75px;
  }
  
  .section-usage p {
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  .carousel .carousel-item {
    margin-top: 130px;
    padding: 10px;
    height: auto;
  }

  .carousel .carousel-item .icon {
    height: 150px;
    width: 150px;
  }

  .carousel .carousel-item h2 {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }

  .carousel .carousel-item p {
    font-size: 0.875rem;
  }
}
