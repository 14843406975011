.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 80px;
}

.policy-paragraph {
    margin-bottom: 20px;
}

.policy-paragraph h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.policy-paragraph h4 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: normal;
}

.policy-paragraph p,
.policy-paragraph ul,
.policy-paragraph li {
    margin-bottom: 10px;
    line-height: 1.6;
}

.policy-paragraph ul {
    list-style-type: disc;
    margin-left: 20px;
}

@media only screen and (max-width: 768px) {
    .privacy-policy-container {
        padding: 20px;
        margin-top: 100px;
    }
}
