.benefit-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    flex: 1;
}

.custom-tick-btn {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #f7faf4;
    border-radius: 50%;
    color: #90bc79;
    flex-shrink: 0;
}

.custom-tick-btn i {
    font-size: 2rem;
}

.benefit-text {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-self: center;
}

.benefit-text h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
}

.benefit-text p {
    margin: 5px 0 0;
    color: #555;
    max-width: 300px;
    overflow-wrap: break-word;
}
