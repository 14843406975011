/* Cart.css */
.cart {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.no-scroll {
  overflow: hidden;
}

.cart.open {
  transform: translateX(0);
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-cart {
  color: black;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.empty-cart {
  text-align: center;
}

.cart-items .cart-item {
  display: flex;
  margin-bottom: 15px;
}

.cart-items .cart-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.cart-items .cart-item button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.quantity-controls {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.quantity-controls button {
  background: none;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.quantity-controls span {
  margin: 0 10px;
}

.shop-now {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #90bc79;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.shop-now:hover {
  background-color: #7aa862;
}

.cart-bottom-section {
  border-top: 1px solid black;
  padding-top: 10px;
  position: sticky;
  bottom: 0;
  background-color: white;
  width: 100%;
}

.subtotal {
  margin-bottom: 5px;
  font-weight: bold;
}

.cart-buttons {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px;
}

.cart-buttons a {
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 20px;
}

.cart-buttons a:hover {
  background-color: green;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.overlay.show {
  display: block;
}


/* Mobile responsive styles */
@media only screen and (max-width: 768px) {
  .cart {
    width: 70%;
  }

  .cart-header {
    justify-content: space-between;
  }

  .cart-header h2 {
    margin-left: 10px;
  }

  
  
  .cart-items .cart-item {
    flex-direction: row;
    align-items: center;
  }

  .cart-items .cart-item img {
    margin-right: 10px;
  }

   .cart-buttons {
    flex-direction: column;
    padding: 10px;
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    background-color: white;
  }

  .cart-buttons a {
    text-align: center;
    margin: 5px auto;
    width: 87%;
  }

  .cart-buttons a:last-child {
    margin-bottom: 0;
  }

  
}

