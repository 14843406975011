/* Newsletter.css */
.newsletter-container {
    position: relative;
    background: url(/public/background.webp) center center no-repeat;
    background-color:  #90bc79;
    background-size: cover;
    padding-top: 60px; /* Adjusted top padding */
    height: 250px;
}

.newsletter-overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, .1);
    clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.container {
    position: relative;
    z-index: 1;
}

.newsletter-title {
    font-size: 40px;
    color: #000;
    margin-top: 20px; /* Adjusted top margin */
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow flex items to wrap */
}

.subscribe-text {
    font-weight: normal; /* Changed font weight to normal */
    margin-right: 9px;
}

.newsletter-name {
    font-weight: bold; /* Changed font weight to bold */
    color: #fff;
}

.newsletter-description {
    color: #fff;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
}

.newsletter-input-container {
    position: relative;
    width: 70%;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: auto; /* Centered the input container */
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center; /* Centered vertically */
}

.newsletter-input {
    background: rgba(255, 255, 255, .3);
    width: calc(100% - 40px); /* Adjusted width for icon */
    padding: 12px 16px; /* Adjusted padding */
    border: none;
    border-radius: 5px;
    position: relative;
}

.newsletter-send-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.3s ease;
    color: #fff; /* Initial icon color */
}

.newsletter-send-icon:hover {
    color: #28a745; /* Changed color on hover */
}

.newsletter-send-icon:active {
    transform: scale(0.95); /* Added scale effect on click */
}


/* Mobile view adjustments */
@media (max-width: 768px) {
    .newsletter-container {
        padding: 30px 10px; /* Adjusted padding for mobile */
        height: auto; /* Allow height to adjust based on content */
    }

    .newsletter-title {
        font-size: 24px; /* Smaller font size for mobile */
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .newsletter-description {
        font-size: 14px; /* Smaller font size for mobile */
        margin-bottom: 20px;
    }

    .newsletter-input-container {
        width: 90%; /* Full width for mobile */
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .newsletter-input {
        width: calc(100% - 30px); /* Adjusted width for icon */
        padding: 10px 12px; /* Adjusted padding for mobile */
    }

    .newsletter-send-icon {
        right: 5px; /* Adjusted position for mobile */
    }
}

