/* src/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 0 !important;
  height: 65px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3 ease;
}

.navbar.transparent {
  background-color: transparent;
}

.navbar.solid {
  background-color: #90bc79;
}

.navbar-brand img {
  height: 100%;
  max-height: 70px;
  margin-left: 20px;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-links a,
.dropbtn,
.cart-icon,
.cart-icon-mobile {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 16px;
  transition: color 0.3s;
  font-family: 'Outfit', Sans-serif;
}

.navbar-links a {
  text-align: left;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */
}

.navbar-links a:hover,
.dropbtn:hover,
.cart-icon:hover,
.cart-icon-mobile:hover {
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%; /* Ensure full width */
}

.dropbtn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s, background 0.3s;
  text-align: left; /* Align text to the left */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */

}

.dropbtn:hover,
.dropbtn:focus {
  color: white;
  background: none;
  outline: none;
}

.dropdown-content {
  margin-left: 12px;
  border-radius: 5px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: black;
  background-color: lightgray;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.cart-icon,
.cart-icon-mobile {
  color: black;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  transition: color 0.3s;
}

.cart-icon:hover,
.cart-icon-mobile:hover {
  color: white;
  border: none;
  background: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Ensure overlay is above everything */
  display: none; /* Hide overlay by default */
}

/* Mobile responsive styles */
@media only screen and (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar-brand {
    order: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: fixed;
    background-color: #90bc79;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    z-index: 1000;
    padding-top: 65px;
    transition: transform 0.3s ease;
    transform: translateX(-100%); /* Hide menu by default */
  }

  .navbar-links.show {
    display: flex;
    transform: translateX(0);
  }

  .navbar-links a {
    margin: 0;
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #fff; /* Add border-bottom */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding in width */
    font-size: 18px;
  }
  .dropbtn {
    margin: 0;
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #fff; /* Add border-bottom */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding in width */
    font-size: 18px;

  }

  .menu-toggle {
    display: block;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 1001;
  }

  .cart-icon-mobile {
    order: 3;
    position: absolute;
    right: 20px; /* Position cart icon on the right */
    top: 25px;
    z-index: 1001; /* Ensure it stays above other elements */
  }

  .cart-icon {
    display: none;
  }

  .overlay.show {
    display: block; /* Show overlay when menu is toggled */
  }

}

@media only screen and (min-width: 769px) {
  .menu-toggle {
    display: none;
  }

  .cart-icon-mobile {
    display: none;
  }
}
