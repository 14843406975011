.product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  position: relative;
}

.product-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background-color: #f9f9f9; /* Light background color */
  width: calc(100% - 200px); /* Subtract the total padding (100px on each side) */
  padding: 100px;
  margin: 0 auto;
}


.product-main {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}



.carousel1 {
  position: relative;
  width: 730px;
  padding-top: 75.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.carousel-images1 {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}


.carousel-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-image1.active {
  opacity: 1;
}

.carousel-indicators1 {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.indicator1 {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator1.active {
  background-color: #4caf50;
}


.product-details {
  flex: 1;
  max-width: 400px;
}

.product-title {
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
}

.product-price {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.product-description {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 20px;
}

.product-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.quantity-input {
  width: 50px;
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
}

.add-to-cart {
  background-color: #90bc79;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
}

.add-to-cart:hover {
  background-color: #7aa862;
}

.product-category {
  font-size: 14px;
  color: #666;
}

.product-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tab-button {
  background: none;
  border: none;
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
  position: relative; /* Needed for the ::after pseudo-element */
}

.tab-button::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: black;
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tab-button:hover {
  background: none;
}

/* Remove the filled button box for active tab */
.tab-button.active {
  background: none;
}

.tab-button:hover::after {
  width: 100%;
}

.tab-button.active::after {
  width: 100%;
}

.product-reviews, .additional-info {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
}

.review {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.review-header {
  display: flex;
  justify-content: space-between;
}

.review-name {
  font-weight: bold;
}

.review-rating {
  color: #f39c12;
}

.review-text {
  margin-top: 5px;
}

.review-pending {
  color: #f39c12;
  font-size: 12px;
}

.add-review {
  margin-top: 20px;
}

.rating-stars {
  display: flex;
  gap: 5px;
}

.star {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
}

.star.selected {
  color: #f39c12;
}

.review-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.review-textarea {
  width: 100%;
  height: 80px;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
  border-width: 2px;
}

.small-input {
  border-width: 2px;
  margin-left: 6px;
  border-radius: 8px;
  width: 20%;
  padding: 5px;
  margin-bottom: 10px;
}


.review-form .name-input {
  order: 1;
}

.review-form .email-input {
  order: 2;
  margin-left: 10px;
}

.small-button {
  font-weight: bold;
  width: 100px;
  height: 40px;
  background-color: #90bc79;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.small-button:hover {
  background-color: #7aa862;
}

.additional-info table {
  width: 100%;
  border-collapse: collapse;
}

.additional-info td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

/* Product.css */
.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 80px; /* Adjust this value to fit below your navbar */
  left: 0;
  z-index: 999;
}


@media only screen and (max-width: 768px){

  .product-section {
    overflow-x: hidden ;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .product-main {
    max-width: 100%;
  }

  .product-details {
    max-width: 100%;
    padding: 0 10px;
    text-align: center;
    margin-left: 0;
  }

  .product-title , .product-price ,.product-category , .product-description{
    text-align: left;
  }

  .product-reviews, .additional-info {
    padding: 0 10px;
  }

  .quantity-input {
    width: 80px; /* Adjusted for mobile */
  }

  .product-reviews, .additional-info {
    width: 90%;
    margin-top: 20px;
  }

  .review-textarea {
    margin-top: 8px;
    width: 95%;
  } 

  .small-input {
    border-width: 2px;
    border-radius: 8px;
    width: 50%;
  }

  .success-message {
    font-size: 14px; 
    padding: 12px;
    margin-top: 5px;
  }

  .carousel1 {
    overflow: hidden; 
    width: 100%;
    padding-top: 75%;
    margin-top: 30%;
    height: 60px;
  }
  
}

/* For tablets and small desktops */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .product-section {
    margin-top: 100px;
    overflow-x: hidden;
    flex-direction: column;
    width: 90%;
    padding: 40px; /* Adjust padding for tablets */
  }

  .product-main {
    flex-direction: column;
    align-items: center;
  }

  .product-details {
    max-width: 600px; /* Adjust width for tablets */
    padding: 0 20px;
  }

  .carousel1 {
    width: 90%;
    padding-top: 60%;
  }
  
  .product-reviews, .additional-info {
    width: 90%;
    margin-top: 20px;
  }
}









