.checkout-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px;
}

.checkout-header {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.checkout-content {
  display: flex;
  justify-content: space-between;
}

.checkout-form {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.section-title {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.checkout-section {
  margin-bottom: 20px;
}

.checkout-section p {
  margin: 0;
}

.checkout-section input, .checkout-section select {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.order-summary {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  background-color: #f9f9f9;
  margin-left: 100px;
}

.order-summary h2 {
  margin-top: 0;
}

.order-summary-item {
  display: flex;
  margin-bottom: 10px;
}

.order-summary-item img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.order-summary-item p {
  margin: 0;
}

.coupon-section {
  display: flex;
  margin-top: 10px;
}

.coupon-section input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

.coupon-section button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-left: 0;
  background-color: #333;
  color: white;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.coupon-section button:hover {
  background-color: #555;
}

.coupon-section p {
  margin-top: 10px;
  color: green;
}

.order-total {
  font-weight: bold;
  margin-top: 20px;
}

.place-order-button {
  width: 104%;
  padding: 15px;
  background-color: #333;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.place-order-button:hover {
  background-color: #555;
}

.place-order-button:disabled {
  cursor: not-allowed;
  background-color: #666;
  color: #ccc;
  pointer-events: none;
}


.payment-card {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.payment-card input[type="radio"] {
  margin-right: 12px;
  position: relative;
  top: 5px; /* Adjust this value if needed */
}

.payment-card input {
  margin-right: 10px;
}

.payment-card label {
  font-size: 16px;
  margin-left: 10px;
}



.payment-details {
  background-color: #eef;
  padding: 10px;
  border: 1px solid #cce;
  border-radius: 4px;
  margin-top: 10px;
}

.payment-details a {
  color: #007BFF;
  text-decoration: none;
}


.payment-details a:hover {
  text-decoration: underline;
}






@media only screen and (max-width: 768px) {
  .checkout-container {
    padding: 20px;
    margin-top: 100px;
  }

  .checkout-header {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .checkout-content {
    flex-direction: column;
  }

  .order-summary {
    margin-left: 0;
    margin-top: 20px;
    order: -1;
  }
  

  .coupon-section {
    flex-direction: column;
  }

  .coupon-section input,
  .coupon-section button {
    border-radius: 4px;
    margin: 5px 0;
  }

  .coupon-section button {
    border-radius: 4px;
  }


  .checkout-section {
    padding: 0 10px; 
    margin-right: 12px;
    margin-left: -8px;
  }


  .checkout-section input,
  .checkout-section select {
    padding: 10px;
    margin: 5px 0 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .place-order-button {
    width: 100%;
  }
  
}