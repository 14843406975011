
.section-contact {
  background-color: #90bc79; /* Green background color */
  text-align: center; /* Center the text horizontally */
  padding: 20px 0; /* Add some padding for spacing */
  color: #fff; /* White text color for better visibility */
  width: 100%; /* Full width */
  margin-top: -65px; /* Ensure there is space from the navbar */
  height: 365px;
  background-image: url("/public/background.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}

.section-contact::before {
  content: "";
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: calc(100% + 65px);
  background: rgba(255, 255, 255, 0.1);
  clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.section-contact h1 {
  margin: 0; /* Remove default margin */
  position: absolute; /* Absolute positioning for fine-tuning */
  top: 60%; /* Adjust this value to move the heading a little below the center */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust vertical positioning */
  font-size: 3rem;
  font-weight: bold;
}


.getintouch-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
  margin-top: 40px; /* Add this line to create space between the navbar and the cards */
}


.message-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
  text-align: center;
}


.message-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}


.contact-form-map {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 2rem;
}


.contact-form,
.map {
  flex: 1;
  max-width: 48%;
  height: 500px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.form-group-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.form-group-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}



.form-group-1 input {
  flex: 1; 
  margin-right: .1rem; 
  padding: 0.7rem; 
  border: 2px solid #ccc;
  border-radius: 4px;  
}


.form-group input,
.form-group textarea
.form-group-1 input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #ccc; 
  border-radius: 4px; 
}


.form-group textarea {
  height: 290px;
}

.contact-form input,
.contact-form textarea {
  width: 100%; /* Make the input fields and textarea take up 100% of the container width */
  padding: 0.95rem;/* Set padding for the input fields and textarea */
  margin-bottom: 0.3rem; /* Set margin bottom for spacing between input fields */
  border: 2px solid #ccc; /* Set border for the input fields and textarea */
  border-radius: 4px; /* Set border radius for rounded corners */
  box-sizing: border-box; /* Ensure padding and border are included in the total width and height */
  font-size: 16px;
}


button {
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #218838;
}


.map iframe {
  width: 100%;
  height: 100%;
  border: none;
}



/* Media Queries for Responsiveness */
@media only screen and (max-width: 768px) {
  .contact-form-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
    padding: 20px; /* Add padding on all sides */
    box-sizing: border-box; /* Ensure padding is included in the width and height */
  }

  .contact-form,
  .map {
    max-width: 100%;
    width: 100%; /* Set width to 100% */
    height: auto; /* Adjust height for smaller screens */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }

  .map {
    margin-top: 30px;
    order: 2; /* Change the order of the map to ensure it comes after the form */
  }

  .message-title {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .form-group-row {
    flex-direction: column;
  }

  .form-group-1 input {
    margin-right: 0;
    margin-bottom: 10px;
  }
}




