/* src/Card.css */
.card {
    background-color: #90bc79;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
  }
  
  .card::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, .1);
    clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
  }
  
  .card-inner {
    width: 85%; /* Adjust as needed to create the desired spacing */
    height: 85%; /* Adjust as needed to create the desired spacing */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid #afcf9f; /* Add white border */
  }
  
  .card-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .card-icon img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 10px;
  }


  .card-icon svg{
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 12px;
    color: black;
  }


  .card-text {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .card-paragraph1 , .card-paragraph2 {
    max-width: 400px;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  