/* src/Home.css */
.section-home {
  background-color: #90bc79;
  text-align: center;
  padding: 50px;
  color: #fff;
  width: 100%;
  margin-top: -65px;
  height: 865px;
  background-image: url("/public/background.webp");
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  box-sizing: border-box;
}

.section-home::before {
  content: "";
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: calc(100% + 65px);
  background: rgba(255, 255, 255, 0.1);
  clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.home-content {
  max-width: 600px;
  text-align: left;
  margin-right: 200px;

}
.home-content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
  text-align: left;
  
}

.light-font {
  font-weight: 300; /* Adjust the value as needed for the desired lightness */
}
.black-text {
  color: black;
}

.home-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.home-content p {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #fff;
  margin-right: 10px;
}

.home-buttons {
  display: flex;
  gap: 1rem;
}

.shop-button, .contact-button {
  padding: 10px 20px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
}

.shop-button {
  background-color: #000;
  color: #fff;
}

.contact-button {
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  border-radius: 5px;
}

.shop-button:hover {
  background-color: #28a745;
}

.contact-button:hover {
  background-color:#28a745;
}
.home-image2{
  display: none;
}

.home-image img {
  padding-top: 150px;
  width: 400px;
  height: auto;
}

.benefits-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin-top: 40px;
}


.services-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 200px;
}

.service-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.service h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.service p {
  font-size: 14px;
  color: #666;
}
.testimonials-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.testimonials-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial {
  max-width: 300px;
  margin: 20px;
  padding: 20px 0;
  text-align: left;
  border-top: 2px solid;
}

.testimonial:nth-child(1) {
  border-color: #ff6347;
}

.testimonial:nth-child(2) {
  border-color: #28a745;
}

.testimonial:nth-child(3) {
  border-color: #17a2b8;
}

.testimonial p {
  font-size: 16px;
  color: #333;
}

.highlight-1 {
  color: #ff6347;
  font-weight: bold;
}

.highlight-2 {
  color: #28a745;
  font-weight: bold;
}

.highlight-3 {
  color: #17a2b8;
  font-weight: bold;
}

.testimonial-author {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.testimonial-author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.testimonial-author p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

@media (max-width: 768px) {
  .section-home {
    background-color: #90bc79;
    text-align: center;
    padding: 20px;
    color: #fff;
    width: 100%;
    margin-top: -65px;
    background-image: url("/public/background.webp");
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    box-sizing: border-box;
  }

  .services-container {
    display: block;
    justify-content: center;
    align-items: center;
  }
  
  .service {
    display: block;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 200px;
    margin-bottom: 40px;
    margin-left: 80px;
  }
  
  .service-icon {
    width: 60px;
    height: 60px;
  }

  .service h4 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
  
  .service p {
    font-size: 14px;
    color: #666;
  }


  .home-content h2{
    text-align: center;  
  }

  .home-content {
    margin-top: 100px;
    margin-right: 0;
    padding: 0 20px;
    text-align: center;
    max-width: 100%;
  }

  .home-content h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .home-content h1 {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .home-content p {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
  }

  .home-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-buttons .shop-button,
  .home-buttons .contact-button {
    font-size: 0.9rem;
    padding: 6px 13px;
    width: 90%;
    max-width: 80px;
    margin-bottom: 10px;
  }
  .home-image img {
    display: none;
  }

  .home-image2  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-image2 img {
    padding-top: 20px;
    max-width: 70%;
    height: auto;
  }

}

@media (min-width: 769px) and (max-width: 1024px) {
  
  .home-image2 img {
    display: none;
  }

  .home-content h1 {
    font-size: 2.5rem;
  }

  .home-content p {
    font-size: 1rem;
  }

  .home-buttons {
    flex-direction: column;
    gap: 0.5rem;
  }

  .shop-button, .contact-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .home-image img {
    width: 600px;
  }

  .home-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-image img {
    max-width: 100%;
  }

  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service {
    margin-bottom: 30px;
    margin-left: 0;
  }
}
