/* Base styles */
.shipping-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px;
  text-align: justify; /* Justify the text */
}

.shipping-policy-container h2 {
  text-align: center; /* Center the heading */
  margin-bottom: 20px;
}

.policy-paragraph {
  font-size: 14px;
  margin-bottom: 20px;
}

.policy-paragraph h3 {
  font-size: 16px; /* Adjust font size for section headings */
  margin-top: 20px;
  color: #333; /* Darker color for headings */
}

.policy-paragraph p {
  margin-bottom: 10px; /* Space between paragraphs */
}

.shipping-policy-container a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline from links */
}

.shipping-policy-container a:hover {
  text-decoration: underline; /* Underline links on hover */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .shipping-policy-container {
    margin-top: 100px;
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .shipping-policy-container h2 {
      font-size: 1.5em; /* Adjust font size for heading on mobile */
  }

  .policy-paragraph {
      font-size: 12px; /* Adjust font size for better readability on mobile */
  }

  .policy-paragraph h3 {
      font-size: 14px; /* Adjust font size for section headings on mobile */
  }
}