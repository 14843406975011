/* src/Benefits.css */

.section-benefits {
  background-color: #90bc79; /* Green background color */
  text-align: center; /* Center the text horizontally */
  padding: 20px 0; /* Add some padding for spacing */
  color: #fff; /* White text color for better visibility */
  width: 100%; /* Full width */
  margin-top: -65px; /* Ensure there is space from the navbar */
  height: 365px;
  background-image: url("/public/background.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}

.section-benefits::before {
  content: "";
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: calc(100% + 65px);
  background: rgba(255, 255, 255, 0.1);
  clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.section-benefits h1 {
  margin: 0; /* Remove default margin */
  position: absolute; /* Absolute positioning for fine-tuning */
  top: 60%; /* Adjust this value to move the heading a little below the center */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust vertical positioning */
  font-size: 3rem;
  font-weight: bold;
}



.benefits-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
  margin-top: 40px; /* Add this line to create space between the navbar and the cards */
}

.highlight {
  display: inline-block;
}

h2 {
  text-align: center;
}

.new-benefits-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #fff;
}

.new-benefits-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.new-benefits-section .highlight {
  color: #69b578; /* Adjust the color to match your design */
}

.new-benefits-section p {
  margin-bottom: 40px;
  color: #555;
}

.benefits-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.benefits-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.benefits-image {
  display: flex;
  justify-content: center; /* Centers the image within its column */
}

.benefits-image img {
  max-width: 70%;
  height: auto;
}


@media (min-width: 768px) {
  .benefits-content {
    flex-direction: row; /* Switch back to row layout for larger screens */
  }

  .benefits-column {
    flex: 1;
  }

  .benefits-image {
    flex: 1;
  }
}